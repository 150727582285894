import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наша колекція | CraftStories - шкіряні витвори мистецтва
			</title>
			<meta name={"description"} content={"Відкрийте для себе основні пропозиції CraftStories. Кожен виріб свідчить про нашу відданість мистецтву та якості. Зануртеся і знайдіть свій наступний омріяний аксесуар"} />
			<meta property={"og:title"} content={"Наша колекція | CraftStories - шкіряні витвори мистецтва"} />
			<meta property={"og:description"} content={"Відкрийте для себе основні пропозиції CraftStories. Кожен виріб свідчить про нашу відданість мистецтву та якості. Зануртеся і знайдіть свій наступний омріяний аксесуар"} />
			<meta property={"og:image"} content={"https://estriter.live/img/00.jpg"} />
			<link rel={"shortcut icon"} href={"https://estriter.live/img/6000699.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://estriter.live/img/6000699.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://estriter.live/img/6000699.png"} />
			<meta name={"msapplication-TileImage"} content={"https://estriter.live/img/6000699.png"} />
			<meta name={"msapplication-TileColor"} content={"https://estriter.live/img/6000699.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" quarkly-title="Advantages/Features-24">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--base"
					lg-text-align="center"
					lg-width="100%"
					text-transform="uppercase"
					letter-spacing="1px"
					color="--orange"
				>
					Наш магазин
				</Text>
				<Text
					margin="0px 0px 28px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					lg-width="100%"
				>
					Наша колекція
				</Text>
				<Text
					margin="0px 0px 6px 0px"
					color="--darkL2"
					font="--lead"
					lg-text-align="left"
					sm-font="--lead"
				>
					Відкрийте для себе основні пропозиції CraftStories. Кожен виріб свідчить про нашу відданість мистецтву та якості. Зануртеся і знайдіть свій наступний омріяний аксесуар
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://estriter.live/img/first.jpg"
					max-width="400px"
					border-radius="8px"
					height="100%"
					object-fit="cover"
					lg-width="100%"
					lg-max-width="none"
				/>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-12">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				flex-direction="column"
				lg-width="100%"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
				sm-grid-template-rows="auto"
				sm-grid-gap="36px 0"
				margin="50px 0px 0px 0px"
				lg-grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-rows="auto"
			>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://estriter.live/img/t5.jpg"
							display="block"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Рукавички світлі S (останній розмір)
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://estriter.live/img/t6.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Ремінь чоловічий світлий М
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://estriter.live/img/t1.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Ремінець М-L темний
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://estriter.live/img/t3.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Портмане чоловіче (чорний)
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://estriter.live/img/t4.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Гаманець класичний
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					justify-self="center"
					sm-display="flex"
					sm-flex-direction="column"
					sm-align-items="center"
					display="flex"
					flex-direction="column"
				>
					<Box min-width="100px" min-height="100px" sm-width="100%" sm-margin="0px 0px 0 0px">
						<Image
							src="https://estriter.live/img/t2.jpg"
							display="block"
							width="100%"
							border-radius="25px"
							lg-max-width="100%"
							max-width="100%"
							object-fit="cover"
							max-height="100%"
							md-height="250px"
							md-width="auto"
							sm-width="100%"
							sm-height="auto"
							height="250px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						flex-direction="column"
						justify-content="center"
						sm-width="100%"
						sm-padding="0px 0px 0px 0"
						margin="25px 0px 0px 0px"
					>
						<Text color="--dark" font="600 18px --fontFamily-sans" lg-margin="0 0px 0px 0px" margin="0 0px 0 0px">
							Ремінець М-L світлий
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				align-self="center"
				padding="20px 0px 0px 0px"
				text-align="center"
			>
				<Button
					background="--color-orange"
					href="/contact-us"
					type="link"
					text-decoration-line="initial"
					border-radius="8px"
				>
					Замовити
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});